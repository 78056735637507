<template>
  <div v-if="show" class="cobranca-window-log-container">
    <div class="cobranca-window-header">
      <div @click="printLog" class="close-btn bg-blue-grey-1"><i class="fa fa-file-pdf m-r-sm"/> Baixar Log</div>
      <div @click="close" class="close-btn">Fechar</div>
    </div>
    <div class="cobranca-window-log erp-terminal" ref="terminal">
      <vue-typed-js :typeSpeed="5" class="hideCursor"
                    :strings="['Buscando logs cobranças e avisos...']">
        <div class="typing font-bold text-positive"></div>
      </vue-typed-js>
      <div v-for="message in buffer" :key="message.id" class="log log-buffer m-t-xs">
        [{{ message.date.date|formatDate }}] [{{message.person}}] [{{message.user}}] {{ message.message }}
      </div>
    </div>
  </div>
</template>

<script>
import {logBuffer} from "@/domain/logs/service/log"
// import { jsPDF } from "jspdf"
// import html2canvas from 'html2canvas'

export default {
  name: "LogEnvioCobranca",
  props: {
    leilao: {
      required: true
    }
  },
  data() {
    return {
      show: true,
      buffer: []
    }
  },
  mounted() {
    this.$logBufferInterval = setInterval(this.loadLogs, 1000)
  },
  beforeDestroy() {
    this.$logBufferInterval && clearInterval(this.$logBufferInterval)
  },
  methods: {
    close() {
      this.show = false
      this.$emit('close')
    },
    printLog() {
      // @TODO: Provisório, Implementar na API
      const win = window.open()
      win.document.write(`
        <!doctype html>
        <html>
          <head>
            <title>Log Operacional de Cobranças do Leilão ${this.leilao.titulo}</title>
          </head>
          <body style="font-family: arial"> <h1 style="font-size: 18px">Log Operacional de Cobranças do Leilão ${this.leilao.titulo}</h1><br>
            <div style="font-size: 12px">${this.$refs.terminal.innerHTML}</div>
          </body>
        </html>
      `)
      win.print()
      win.close()
      return
    },
    loadLogs() {
      logBuffer('cobrancaLeilao', 'leilao', this.leilao.id)
          .then(({data}) => {
            this.buffer = data
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style lang="stylus">
.cobranca-window-log-container {
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  width 100%
  height 100%
  margin auto
  display flex
  justify-content center
  align-items center
  flex-direction column
  background rgba(0, 0, 0, 0.2)
  z-index 600
}

.cobranca-window-log {
  width 800px
  max-width 800px
  max-height 80%
  border-radius 4px
  background-color #0d1117
  -webkit-box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 1);
  box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 1);
  padding 20px
  overflow auto
  color #27c24c
}

.cobranca-window-header {
  width 800px
  max-width 800px
  margin-bottom 10px
  display flex
  justify-content space-between

  .close-btn {
    display flex
    background-color #FFFFFF
    padding 10px
    border-radius 4px
    color #666666
    cursor pointer
    -webkit-box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 0.4);
    box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 0.4);

    &:hover {
      filter brightness(1.03)
    }

  }
}

</style>
